export const fakeLoginApi = (username: string, password: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (username === 'user' && password === 'password') {
        resolve({ user: { username }, token: 'valid-token' });
      } else {
        reject(new Error('Invalid credentials'));
      }
    }, 1000);
  });
};

export const fakeGetUserApi = (token: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (token === 'valid-token') {
        resolve({ username: 'user' });
      } else {
        reject(new Error('Invalid token'));
      }
    }, 1000);
  });
};
